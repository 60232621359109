<template>
  <v-dialog v-model="isOpened" width="500">
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Selectionner le salon à associer
      </v-card-title>

      <v-card-text>
        <v-select
            v-model="selectedSalon"
            :items="salons"
            labels="Salon"
            item-text="valueName"
            item-value="valueName"
            placeholder="Salon"
        >
        </v-select>

        <v-menu
            ref="menu"
            v-model="dateSalonMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="dateSalon"
                label="Date du salon"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="dateSalon"
              no-title
              scrollable
              @input="dateSalonMenu = false"
          >
          </v-date-picker>
        </v-menu>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="linkSalonProv()"> Valider</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "LinkDialog",
  props: ["dialog"],
  data() {
    return {
      selectedSalon: null,
      dateSalon: new Date().toISOString().substr(0, 10),
      dateSalonMenu: false,
    };
  },
  computed: {
    isOpened: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("setOpened", value);
      },
    },
    salons: function () {
      return this.$store.getters.salonsSf;
    },
  },
  watch: {
    dialog: function (value) {
      if (value) {
        this.selectedSalon = null;
      }
    },
    selectedSalon: function (value) {
      if (value) {
        this.selectSalon();
      }
    },
    dateSalon: function (value) {
      this.$emit("setDateSalon", value);
    },
  },
  methods: {
    linkSalonProv() {
      this.$emit('linkSalonProv')
    },
    selectSalon() {
      this.$emit('selectSalonSF', this.selectedSalon)
    },
  },
}

</script>

<style scoped>

</style>


