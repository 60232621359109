<template>
  <div class="prospects">
    <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="3000" top>
      <v-icon>{{ snackbar.icon }}</v-icon>
      {{ snackbar.text }}
    </v-snackbar>
    <v-row class="ma-4">
      <v-col cols="12" sm="12">
        <v-card class="mx-auto"  :loading="tableLoading">
          <v-card-title
          >Prospects
            <v-spacer></v-spacer>
            <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-width="200"
                offset-y
            >
              <template v-slot:activator="{ on, attrs }">

                <v-btn color="primary" dark v-bind="attrs" v-on="on">
                  <v-badge
                      color="error"
                      dot
                      v-if="filters.prospectFilter || filters.salonFilter || filters.SFErrorsFilter !== false"
                  >
                    <v-icon>mdi-filter</v-icon>

                  </v-badge>
                  <v-icon v-else>mdi-filter</v-icon>

                </v-btn>
              </template>

              <v-card>
                <v-card-title>Filtrer le tableau</v-card-title>
                <v-card-text>
                  <v-select
                      :items="salons"
                      item-text="label"
                      item-value="label"
                      label="Filtrer par salons"
                      v-model="filters.salonFilter"
                  ></v-select>
                  <v-text-field
                      v-model="filters.prospectFilter"
                      label="Filter par nom"
                  ></v-text-field>
                  <v-checkbox
                      label="Uniquement marqués comme erreur salesforce"
                      v-model="filters.SFErrorsFilter"
                  ></v-checkbox>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn text color="error" @click="resetFilters">
                    Vider les filtres
                  </v-btn>
                  <v-btn color="primary" text @click="menu = false">
                    Fermer
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-card-title>

          <v-data-table :headers="headers" :items="shownProspect"  :options.sync="options"  :server-items-length="nombreProspects">
            <template v-slot:[`item.salon`]="{ item }">
              <v-tooltip bottom v-if="!item.salon_SF">
                <template
                  v-slot:activator="{ on, attrs }"
                >
                  <div v-bind="attrs" v-on="on">{{item.salon_SF}}</div>
                </template>
                <span>Non synchronisé</span>
              </v-tooltip>
              <v-tooltip bottom v-if="item.salon_SF">
                <template
                    v-slot:activator="{ on, attrs }"
                >
                  <div v-bind="attrs" v-on="on">{{item.salon_SF}}</div>
                </template>
                <span>Salon provisoire: {{ item.salon_provisoire }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.specSf`]="{ item }">
              <div class="programme" v-for="s in item.specSf" :key="s.id">
                <v-chip class="ma-1">{{ s.name }}</v-chip>
              </div>
            </template>
            <template v-slot:[`item.createdAt`]="{ item }">
              {{ format(new Date(item.createdAt), "DD/MM/YYYY - HH:mm") }}
            </template>
            <template v-slot:[`item.updatedAt`]="{ item }">
              {{ format(new Date(item.updatedAt), "DD/MM/YYYY - HH:mm") }}
            </template>
            <template v-slot:[`item.statut_SF`]="{ item }">
              <v-chip
                  dense
                  color="success"
                  text-color="white"
                  v-if="item.statut_SF === true"
              >
                <v-icon>mdi-check</v-icon>
              </v-chip>
              <v-chip
                  color="error"
                  text-color="white"
                  v-if="item.statut_SF === false"
              >
                <v-icon>mdi-window-close</v-icon>
              </v-chip>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip bottom v-if="item.statut_SF === false && item.salon_SF">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      @click="exportToSalesForce(item)"
                      class="showIcon"
                  >
                    <v-icon>mdi-export</v-icon>
                  </v-btn>
                </template>
                <span>Exporter vers SalesForce</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      @click="openShowDialog(item)"
                      class="showIcon"
                  >
                    <v-icon>mdi-information-outline</v-icon>
                  </v-btn>
                </template>
                <span>Details</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      @click="openEditDialog(item)"
                      class="editIcon"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Modifier</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template
                    v-slot:activator="{ on, attrs }"
                    v-if="$store.getters.user.role === 'admin'"
                >
                  <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      class="deleteIcon"
                      @click="openDeleteDialog(item)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Supprimer</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <ShowDialog
        :opened="showDialog"
        @setOpened="setDialogOpenStatus"
        :item="selectedProspect"
        :type="showType"
    ></ShowDialog>
    <EditDialog
        :opened="editDialog"
        @setOpened="setDialogEditStatus"
        :item="editedItem"
        :type="'prospect'"
        @editDone="editDone"
    ></EditDialog>
    <delete-dialog
        v-if="prospectToDelete"
        :opened="deleteDialog"
        :itemName="prospectToDelete.nom"
        @setOpened="setDialogDeleteStatus"
        @confirm="deleteProspect"
    ></delete-dialog>
    <loading-dialog
        class="loadingDialog"
        :opened="loadingOpened"
    ></loading-dialog>
  </div>
</template>

<script>
import ShowDialog from "@/components/Dialogs/ShowDialog";
import EditDialog from "@/components/Dialogs/EditDialog";
import DeleteDialog from "@/components/Dialogs/DeleteDialog";

import {format} from "fecha";
import LoadingDialog from "@/components/Dialogs/LoadingDialog.vue";
import api from "@/plugins/api";

export default {
  name: "ProspectView",
  components: {
    LoadingDialog,
    DeleteDialog,
    ShowDialog,
    EditDialog,
  },
  async mounted() {
    console.log('mounted')
    await this.$store.dispatch('fetchSalonsSf')
    await this.$store.dispatch('fetchSalonsProvisoires')
    await this.$store.dispatch('fetchProgrammes')
    await this.$store.dispatch('fetchSfConfs')
    await this.$store.dispatch('fetchHistorique')
    this.specSF = this.$store.getters.sfConfs;
    this.formatProspects();
  },
  computed: {
    salons() {
      return this.$store.getters.salonsSf;
    },
    nombreProspects() {
      return this.$store.getters.nombreProspects;
    },
    prospects() {
      return this.$store.getters.prospects;
    },
  },
  data() {
    return {
      headers: [
        {text: "Nom", align: "start", sortable: true, value: "nom"},
        {text: "Prenom", value: "prenom", sortable: true},
        {text: "Email", value: "email", sortable: true},
        {text: "Produits Choisis", value: "specSf", sortable: true},
        {text: "Salon", value: "salon", sortable: true},
        {text: "Date de création", value: "createdAt", sortable: true},
        {
          text: "Date de dernière modification",
          value: "updatedAt",
          sortable: true,
        },
        {text: "Statut SalesForce", value: "statut_SF", sortable: true},
        {text: "Actions", value: "actions", sortable: false, align: "center"},
      ],
      options :{},
      showDialog: false,
      showType: "prospect",
      editDialog: false,
      editedItem: null,
      snackbar: {
        show: false,
        text: "",
        color: "",
        icon: "",
      },
      selectedProspect: null,
      prospectToDelete: null,
      deleteDialog: false,
      menu: false,
      specSF: [],
      filters: {
        salonFilter: null,
        prospectFilter: null,
        SFErrorsFilter: false,
      },
      shownProspect: [],
      loadingOpened: false,
      tableLoading: false,
    };
  },
  watch:{
    options:{
      handler(){
        this.formatProspects();
      },
      deep:true
    },
    filters:{
      handler(newVal, oldVal){
        // check if the filters have changed by at least 3 characters
        if (newVal.prospectFilter && newVal.prospectFilter.length > 2) {
          this.options = {...this.options, ...newVal};
        } else if (newVal.salonFilter) {
          this.options = {...this.options, ...newVal};
        } else if (newVal.SFErrorsFilter) {
          this.options = {...this.options, ...newVal};
        }else if (newVal.prospectFilter === "" && oldVal.prospectFilter !== "") {
          this.options = {...this.options, ...newVal};
        } else if (newVal.salonFilter === "" && oldVal.salonFilter !== "") {
          this.options = {...this.options, ...newVal};
        } else if (newVal.SFErrorsFilter === false && oldVal.SFErrorsFilter !== false) {
          this.options = {...this.options, ...newVal};
        }
      },
      deep:true
    }
  },
  methods: {
    setDialogOpenStatus(status) {
      this.showDialog = status;
    },
    openShowDialog(item) {
      this.selectedProspect = item;
      this.setDialogOpenStatus(true);
    },
    setDialogEditStatus(status) {
      this.editDialog = status;
    },
    openEditDialog(item) {
      let that = this;
      this.editedItem = item;
      that.setDialogEditStatus(true);
    },
    editDone() {
      this.setDialogEditStatus(false);
      this.snackbar.show = true;
      this.snackbar.text = "Prospect modifié";
      this.snackbar.color = "success";
      this.snackbar.icon = "mdi-check";
      // this.formatProspects();
    },
    openDeleteDialog(item) {
      this.prospectToDelete = item;
      this.setDialogDeleteStatus(true);
    },
    setDialogDeleteStatus(status) {
      this.deleteDialog = status;
    },
    deleteProspect() {
      let that = this;
      api
          .post("/deleteProspect", {
            id: this.prospectToDelete.prospect_id,
          })
          .then(function (response) {
            that.setDialogDeleteStatus(false);
            if (response.data.error) {
              throw new Error(response.data.error);
            }
            ////console.log(response);
          });
    },
    format,

    formatProspects() {
      this.tableLoading = true;
      this.$store.dispatch("fetchProspects", this.options).then(() => {
        let p = this.prospects;
        if (p.length > 0) {
          for (let i = 0; i < p.length; i++) {
            if (Array.isArray(p[i].spec_id)) {
              p[i].specSf = this.$store.getters.sfConfs.filter((item) =>
                p[i].spec_id.includes(item.id)
              );
              p[i].specSf.filter((x) => x !== undefined);
            } else {
              p[i].specSf = [];
              p[i].specSf.push(
                this.$store.getters.sfConfs.filter(
                  (x) => x.id === parseInt(p[i].spec_id)
                )[0]
              );
              p[i].specSf.filter((x) => x !== undefined);
            }
          }


          this.shownProspect = p;
          console.log(this.shownProspect)
          this.tableLoading = false;

        }else{
          this.shownProspect = [];
        }
      });
    },
    exportToSalesForce(prospect) {
      console.log(prospect);
      api.post('/legacyExportSingleProspect', {prospect}).then(() => {
          this.snackbar.show = true;
          this.snackbar.text = "Prospect exporté";
          this.snackbar.color = "success";
          this.snackbar.icon = "mdi-check";
          this.formatProspects();
      }).catch((error) => {
          this.snackbar.show = true;
          this.snackbar.text = "Erreur lors de l'export, veuillez contacter le supoort";
          this.snackbar.color = "error";
          this.snackbar.icon = "mdi-alert";
          console.log(error);
      })

    },
    resetFilters() {
      this.filters.prospectFilter = null;
      this.filters.salonFilter = null;
      this.filters.SFErrorsFilter = false;
      console.log(this.filters);
      this.formatProspects()
    },
  },
};
</script>

<style scoped></style>
